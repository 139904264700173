@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'ClashDisplay-Extralight';
	src: url('fonts/ClashDisplay-Extralight.otf') format('opentype');
	font-weight: 200;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'ClashDisplay-Light';
	src: url('fonts/ClashDisplay-Light.otf') format('opentype');
	font-weight: 300;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'ClashDisplay-Regular';
	src: url('fonts/ClashDisplay-Regular.otf') format('opentype');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'ClashDisplay-Medium';
	src: url('fonts/ClashDisplay-Medium.otf') format('opentype');
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'ClashDisplay-Semibold';
	src: url('fonts/ClashDisplay-Semibold.otf') format('opentype');
	font-weight: 600;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'ClashDisplay-Bold';
	src: url('fonts/ClashDisplay-Bold.otf') format('opentype');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* Link hover style */

.link-hover-effect-1 {
	position: relative;
	display: inline-block;
	overflow: hidden;
	padding: 0.5rem 0;
}

.link-hover-effect-1::before {
	content: "";
	position: absolute;
	left: 0px;
	bottom: 0px;
	/* top: 45px; */
	height: 4px;
	width: 40%;
	border-radius: 5px 5px 0 0;
	background-color: #F77F00;
	transform: translateX(-105%);
	transition: transform 0.3s ease-in-out;
}

.link-hover-effect-1:hover::before {
	transform: translateX(0);
	left: 45px;
}

.link-hover-effect-1:hover {
	text-decoration: none;
	color: #F77F00;
	/* transition: transform 0.5s ease-in-out; */
}


/* Link hover style */

html{
	scroll-behavior: smooth;
}

.hero-bg {
	background: url('images/hero-bg.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
@media only screen and (min-width: 768px) {
	.vertical-text {
			writing-mode: vertical-rl;
			text-orientation: mixed;
			transform: rotate(180deg);
			margin-right: 10px;
			/* Adjust as needed */
		}
}
.vert-text {
	writing-mode: vertical-rl;
	text-orientation: upright;
	transform: rotate(180deg);
	display: inline-block;
}


.viewmore {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.footer-bg {
	background:
		linear-gradient(to bottom right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
		url('images/footer-bg.png')
	;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.conf-bg {
	background: url('images/conf-bg.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	/* height: 80dvh; */
}

.horizontal-line {
	border-top: 2px solid black;
	width: 100%;
}
.didache-bg{
	background: url('images/didache-bg.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
@media only screen and (max-width: 600px) {
	.didache-bg {
		min-height: 100dvh;
	}
}
.imgBlur-bg:hover{
	background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
	position: absolute;
}
.findusHero-bg {
	background: url('images/findus-hero.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 70dvh;
}
.map-bg{
	background: url('images/map-bg.png'),
		rgba(255, 255, 255, 0.1);
	background-repeat: no-repeat;
	background-position: top;
	background-size: contain;
}
.aboutusHero-bg {
	background: url('images/aboutus-hero.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 70dvh;
}

/* Carousel style */
.carousel {
	position: relative;
	width: 100%;
	max-width: 600px;
	overflow: hidden;
	margin: auto;
}

.carousel-inner {
	display: flex;
	transition: transform 0.5s ease-in-out;
}

.carousel-item {
	min-width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.carousel-control {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: rgba(0, 0, 0, 0.5);
	border: none;
	color: white;
	font-size: 24px;
	padding: 10px;
	cursor: pointer;
}

.carousel-control.prev {
	left: 10px;
}

.carousel-control.next {
	right: 10px;
}